<template>
  <p class="h2">
    {{ user.name }}
  </p>
  <form @submit.prevent="onSubmit">
    <div class="mb-3">
      <label for="name" class="form-label">
        Name:
      </label>
      <input id="name" v-model="user.name" type="text" class="form-control">
    </div>
    <button type="submit" class="btn btn-primary">
      Submit
    </button>
  </form>
</template>
<script>
import axios from "axios";
import router from "@/router";

export default {
  name: "UserEdit",
  components: {},
  props: {
    id: {
      type: String,
      //required: true,
      default: undefined,
    },
  },
  data() {
    return {
      isNew: true,
      user: {},
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    onSubmit: async function () {
      if (this.id) {
        // update
        await axios.put(`/api/rest/dao/user/${this.id}`, {
          name: this.user.name,
        });
        return router.back();
      } else {
        // create
        await axios.post(`/api/rest/dao/user/`, {
          name: this.user.name,
        });
        return router.back();
      }
    },
    load: async function () {
      
      try {
        this.isNew = !!(this.id);
        this.user = {
          _id: this.id,
          name: undefined,
        };

        if (this.isNew) {
          const { data: user } = await axios.get(
            `/api/rest/dao/user/${this.id}`
          );
          this.user = user;
        }
        
      } catch (err) {
        this.errors = err.response.data;
      }
    },
  },
};
</script>
